import {
  hydrateNever,
  hydrateWhenVisible,
  hydrateWhenIdle
} from 'vue-lazy-hydration';

// Import all element components
const components = {
  // (Atoms)
  headline: hydrateNever(() => import('@/components/atoms/Headline')),
  divider: hydrateNever(() => import('@/components/atoms/Divider')),
  profileDetails: () => import('@/components/atoms/ProfileDetails'),
  // (Molecules)
  imageElement: hydrateNever(() => import('@/components/molecules/ImageElement')),
  imageBanner: hydrateNever(() => import('@/components/molecules/ImageBanner')),
  textBanner: hydrateWhenVisible(() => import('@/components/molecules/TextBanner')),
  hero: () => import('@/components/molecules/Hero'),
  // (Organisms)
  sectionElement: () => import('@/components/organisms/SectionElement'),
  ratingExperience: hydrateWhenVisible(() => import('@/components/organisms/RatingExperience')),
  checkout: () => import('@/components/organisms/ShopCommerce/Checkout'),
  heroSlider: () => import('@/components/organisms/HeroSlider'),
  textImage: hydrateWhenVisible(() => import('@/components/organisms/TextImage')),
  spotsManual: hydrateWhenVisible(() => import('@/components/organisms/SpotsManual')),
  spotsDynamic: hydrateWhenVisible(() => import('@/components/organisms/SpotsDynamic')),
  qoute: hydrateWhenVisible(() => import('@/components/organisms/Qoute')),
  textElement: hydrateWhenVisible(() => import('@/components/organisms/TextElement')),
  employeeGrid: () => import('@/components/organisms/EmployeeGrid'),
  twoColumnText: hydrateWhenVisible(() => import('@/components/organisms/TwoColumnText')),
  // multipleImages: () => import('@/components/organisms/MultipleImages'),
  accordionList: hydrateWhenVisible(() => import('@/components/organisms/AccordionList')),
  valuePropositions: hydrateWhenVisible(() => import('@/components/organisms/ValuePropositions')),
  htmlBlock: hydrateWhenIdle(()=> import('@/components/organisms/HtmlBlock')),
  newsletter: hydrateWhenIdle(() => import('@/components/organisms/Newsletter')),
  collage: hydrateWhenVisible(() => import('@/components/organisms/CollageManual')),
  collageDynamic: hydrateWhenVisible(() => import('@/components/organisms/CollageDynamic')),
  productListDynamic: () => import('@/components/organisms/ShopCommerce/ProductListDynamic'),
  productListSmartSearch: () => import('@/components/organisms/ShopCommerce/ProductListSmartSearch'),
  productDetail: () => import('@/components/organisms/ShopCommerce/ProductDetail'),
  categoryManual: hydrateWhenVisible(() => import('@/components/organisms/ShopCommerce/CategoryManual')),
  categoryDynamic: () => import('@/components/organisms/ShopCommerce/CategoryDynamic'),
  login: () => import('@/components/organisms/Login'),
  impersonation: () => import('@/components/organisms/Impersonation'),
  createUser: () => import('@/components/organisms/CreateUser'),
  receipt: () => import('@/components/organisms/ShopCommerce/Receipt'),
  favoriteList: () => import('@/components/organisms/ShopCommerce/FavoriteList'),
  assortmentsList: () => import('@/components/organisms/ShopCommerce/AssortmentsList'),
  latestBoughtProductsList: () => import('@/components/organisms/ShopCommerce/LatestBoughtProductsList'),
  changePassword: () => import('@/components/organisms/ChangePassword'),
  resetPassword: () => import('@/components/organisms/ResetPassword'),
  productCarousel: hydrateWhenVisible(()=> import('@/components/organisms/ShopCommerce/ProductCarousel')),
  productCarouselSmartSearch: hydrateWhenVisible(()=> import('@/components/organisms/ShopCommerce/ProductCarousel')),
  brandOverview: hydrateWhenVisible(()=> import('@/components/organisms/ShopCommerce/BrandOverview')),
};

export default {
  components,
  props: {
    content: {
      required: true,
      type: Object
    }
  },
  methods: {
    getSpacingVertical (contentItem) {
      let excludeElements = ['sectionelement'];
      return !excludeElements.includes( contentItem.ncContentTypeAlias.toLowerCase() ) ? 'px-3 px-lg-5' : '';
    },
    getElementTemplate (contentItem) {

      let nestedContentTypeAlias = contentItem.ncContentTypeAlias.toLowerCase().includes('form') ? 'formElement' : contentItem.ncContentTypeAlias;
      // Find the component template
      if (contentItem && components[nestedContentTypeAlias]) {
        return nestedContentTypeAlias;
      } else {
        console.warn(
          `The template for: ${contentItem.ncContentTypeAlias} was not found`
        );
        return;
      }
    }
  }
};
